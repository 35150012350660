import {
  Component,
  ComponentRef,
  ElementRef,
  ViewChild,
  WritableSignal,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IToast } from '../../service/toast.service';
import { FontIconComponent } from '../font-icon/font-icon.component';

@Component({
  selector: 'app-toast',
  standalone: true,
  imports: [CommonModule, FontIconComponent],
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.css',
})
export class ToastComponent {
  ref: WritableSignal<ComponentRef<ToastComponent> | null> = signal(null);
  toast: WritableSignal<IToast | null> = signal(null);

  @ViewChild('container') container!: ElementRef<HTMLElement>;

  ngAfterViewInit() {
    setTimeout(() => {
      this.container.nativeElement.classList.remove('-translate-y-10');
      this.container.nativeElement.classList.remove('opacity-0');
    });
  }

  onClose() {
    this.container.nativeElement.classList.add('-translate-y-10');
    this.container.nativeElement.classList.add('opacity-0');
    setTimeout(() => this.ref()?.destroy(), 500);
  }
}
