<div
  #container
  class="opacity-0 -translate-y-10 transition-all duration-500 transform flex gap-2 items-center w-full max-w-xs p-4 text-gray-500 bg-white rounded-lg shadow"
  role="alert"
>
  <app-font-icon [icon]="$any(toast()?.type)"></app-font-icon>
  <div class="text-sm font-normal">{{ toast()?.content }}</div>
  <button
    (click)="onClose()"
    type="button"
    class="btn-close"
    data-dismiss-target="#toast-default"
    aria-label="Close"
  >
    <span class="sr-only">Close</span>
    <app-font-icon [icon]="'close'"></app-font-icon>
  </button>
</div>
